<template>
  <ion-page>
    <ion-header>
      <ion-toolbar :style="menuStyle">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title> {{ content.privacyPolicyTitle }} </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :style="contentStyle">
      <div class="content">
        <div class="et_pb_module et_pb_image et_pb_image_1">
          <span class="et_pb_image_wrap "
            ><img
              src="https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi.png"
              alt=""
              title="TKTP Logo Final Horizontal 300dpi"
              srcset="
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi.png          2500w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-300x81.png    300w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-1024x275.png 1024w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-768x206.png   768w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-1536x412.png 1536w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-2048x550.png 2048w,
                https://www.tk2p.com/wp-content/uploads/2021/11/TKTP-Logo-Final-Horizontal-300dpi-1080x290.png 1080w
              "
              sizes="(max-width: 2500px) 100vw, 2500px"
              class="wp-image-425"
          /></span>
        </div>
        <div v-html="content.privacyPolicyDescription"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { useStore } from "vuex";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton
} from "@ionic/vue";

import { computed, onMounted } from "vue";

export default {
  name: "Term",
  components: { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton },
  setup() {
    const store = useStore();
    const contentStyle = store.state.content;
    const menuStyle = store.state.menu;

    const content = computed(() => {
      return store.state.app.appSettings || {};
    });

    onMounted(() => {
      store.dispatch("axAppSettings");
    });

    return {
      content,
      contentStyle,
      menuStyle,
    };
  },
};
</script>
